
export default {
  middleware: ["public"],
  head() {
    if (this.story) {
      return this.$head(this.story);
    }
  },
  asyncData(context) {
    if(context.route.name == 'lang-compare' && context.store.state.compare.items.length < 2){
      return context.redirect(
        "/" + context.$config.defaultLanguage + '/products'
      );
    }
    var endpoint = ["storyblok", "stories"];
    if (context.route.name == "lang") {
      endpoint.push("homepage");
    } else if (context.route.params.slug) {
      endpoint.push(context.route.path.includes("support") ? "support" : "pages");
      endpoint.push(context.route.params.slug);
    } else {
      endpoint.push(
        context.route.path.replace("/" + context.route.params.lang + "/", "")
      );
    }
    endpoint = endpoint.join("/");
    return context.$axios
      .get(endpoint)
      .then((asyncRes) => {
        return { story: asyncRes.data.story };
      })
      .catch((asyncErr) => {
        var err = asyncErr.stack;
        if (asyncErr.response) {
          var err =
            asyncErr.response.data &&
            asyncErr.response.data.stories &&
            asyncErr.response.data.stories[0]
              ? asyncErr.response.data.stories[0]
              : asyncErr.response.data;
        }
        context.error({
          statusCode: asyncErr.response ? asyncErr.response.status : 500,
          message: err,
        });
      });
  },
};
